<template>
  <div class="levelOneDiv" style="background-color: #fff;">
    <!-- <div style="padding: 10px 20px 10px; ">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="item in breadList" :key="item.key">{{ item.value }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div v-if="!componentName" class="table_box">
      <div class="box1">
        <templateTree ref="templateTree" :treeData="treeData" :dck="dck" @refreshNode="refreshNode" />
      </div>

      <div class="box2">
        <div class="table_right">
          <!-- 搜索筛选 -->
          <el-form
            :inline="true"
            :model="searchObj"
            :rules="rules"
            ref="form"
            style="align-items: center;margin-bottom: 4px;"
            class="user-search flex-between"
            @submit.native.prevent
          >
            <div>
              <el-form-item label="预算年度" prop="year">
                <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
              </el-form-item>

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-search" @click="search"></el-button>
              </el-form-item>

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-refresh-right" @click="reset"></el-button>
              </el-form-item>
            </div>

            <div>
              <el-form-item>
                <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAdd('add', {})">新建</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-download" @click="importExcel()"
                  >导入</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-upload2" @click="exportExcel()"
                  >导出</el-button
                >
              </el-form-item>

              <!-- <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-s-data" @click="handleView('charts', {})"
                  >查看统计图</el-button
                >
              </el-form-item> -->
            </div>
          </el-form>

          <div style="position: relative;">
            <el-table
              v-loading="loading"
              ref="table"
              :data="listData"
              style="width: 100%;position: absolute;"
              height="740px"
            >
              <el-table-column fixed type="index" label="序号" width="60" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.$index != 0">{{
                    (pageparm.currentPage - 1) * pageparm.pageSize + scope.$index
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column fixed prop="county" align="center" width="100" label="行政区域"></el-table-column>

              <el-table-column align="center" label="项目基本情况">
                <el-table-column align="center" width="100" prop="plannedProjects" label="计划项目（个）">
                </el-table-column>
                <el-table-column align="center" width="100" prop="affectedTownships" label="涉及移民乡（个）">
                </el-table-column>
                <el-table-column align="center" width="100" prop="affectedVillages" label="涉及移民村（个）">
                </el-table-column>
                <el-table-column align="center" width="100" prop="affectedGroups" label="涉及移民组（个）">
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="前期工作">
                <el-table-column align="center" label="确定方式">
                  <el-table-column align="center" width="100" prop="plannedProjectsInPlan" label="规划内项目（个）">
                  </el-table-column>
                  <el-table-column align="center" width="100" prop="selfSelectedProjects" label="移民自选项目（个）">
                  </el-table-column>
                  <el-table-column align="center" width="100" prop="participatedProjects" label="移民参与项目（个）">
                  </el-table-column>
                  <el-table-column align="center" width="100" prop="publicizedProjects" label="公示项目（个）">
                  </el-table-column>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="项目实施">
                <el-table-column align="center" label="工程量">
                  <el-table-column align="center" width="100" prop="incompleteProjects" label="未完成项目（个）">
                  </el-table-column>
                  <el-table-column align="center" width="100" prop="completedProjects" label="完成项目（个）">
                  </el-table-column>
                  <el-table-column align="center" width="110" prop="completionRate" label="完成率（%）">
                    <template slot-scope="scope">
                      <span v-if="scope.$index != 0"
                        >{{ scope.row.completionRate ? scope.row.completionRate + '%' : '' }}
                      </span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="投资">
                  <el-table-column align="center" width="110" prop="investmentPlan" label="计划（万元）">
                    <template slot-scope="scope">
                      {{ formatToTenThousandWithCustomCommas(scope.row.investmentPlan) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="110" prop="investmentCompleted" label="完成（万元）">
                    <template slot-scope="scope">
                      {{ formatToTenThousandWithCustomCommas(scope.row.investmentCompleted) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="110" prop="investmentCompletionRate" label="完成率（%）">
                    <template slot-scope="scope">
                      <span v-if="scope.$index != 0">{{
                        scope.row.investmentCompletionRate ? scope.row.investmentCompletionRate + '%' : ''
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="110" prop="investmentAllocation" label="拨付（万元）">
                    <template slot-scope="scope">
                      {{ formatToTenThousandWithCustomCommas(scope.row.investmentAllocation) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="110" prop="investmentAllocationRate" label="拨付率（%）">
                    <template slot-scope="scope">
                      <span v-if="scope.$index != 0"
                        >{{ scope.row.investmentAllocationRate ? scope.row.investmentAllocationRate + '%' : '' }}
                      </span>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="项目管理">
                <el-table-column align="center" width="100" prop="tenderProjects" label="应招投标项目（个）">
                </el-table-column>
                <el-table-column align="center" width="100" prop="actualTenderProjects" label="实际招投标项目（个）">
                </el-table-column>
                <el-table-column align="center" width="100" prop="supervisionProjects" label="应建设监理项目（个）">
                </el-table-column>
                <el-table-column
                  align="center"
                  width="100"
                  prop="actualSupervisionProjects"
                  label="实际建设监理项目（个）"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  width="100"
                  prop="immigrationSelfBuiltProjects"
                  label="移民自建项目（个）"
                >
                </el-table-column>
                <el-table-column align="center" width="100" prop="plannedChangeProjects" label="计划变更项目（个）">
                </el-table-column>
                <el-table-column
                  align="center"
                  width="100"
                  prop="unapprovedChangeProjects"
                  label="其中未批准计划变更项目（个）"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  width="100"
                  prop="participationSupervisionProjects"
                  label="移民参与监督项目（个）"
                >
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="项目验收">
                <el-table-column
                  align="center"
                  width="100"
                  prop="completedAcceptanceProjects"
                  label="完成项目验收的（个）"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  width="100"
                  prop="ongoingAcceptanceProjects"
                  label="正在实施项目验收的（个）"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  width="100"
                  prop="completeDocumentationProjects"
                  label="档案资料齐全（个）"
                >
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="受益人口">
                <el-table-column align="center" width="100" prop="totalBeneficiaries" label="合计（人或人次）">
                </el-table-column>
                <el-table-column
                  align="center"
                  width="100"
                  prop="immigrationBeneficiaries"
                  label="其中:移民（人或人次）"
                >
                </el-table-column>
              </el-table-column>

              <el-table-column
                align="center"
                width="100"
                prop="informationAccessCount"
                label="查阅相关资料和信息（条）"
              >
              </el-table-column>
              <el-table-column
                align="center"
                width="100"
                prop="visitsToImmigrantMeetings"
                label="走访或与移民群众座谈（人/次）"
              >
              </el-table-column>

              <el-table-column align="center" fixed="right" label="操作" width="90">
                <template slot-scope="scope">
                  <div v-if="scope.$index != 0" class="flex" style="justify-content: center;">
                    <el-link v-if="scope.$index != 0" type="primary" @click="handleView('view', scope.row)"
                      >查看</el-link
                    >
                    &nbsp; &nbsp;
                    <el-link v-if="scope.$index != 0" type="danger" @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        </div>
      </div>
    </div>

    <formHandle
      v-else="componentName"
      ref="formHandle"
      @refreshTable="refreshTable"
      @handleBack="handleBack"
    ></formHandle>

    <tempalteImport ref="tempalteImport" @refreshTable="refreshTable" />
    <!-- 分页组件 -->
  </div>
</template>

<script>
import {
  _PorjectTypicalSpotChecksPage,
  _PorjectTypicalSpotChecksExport,
  _PorjectTypicalSpotChecksDelete
} from '@/api/modular/postImmigrationService/policy/typicalSpotCheck.js'

import Pagination from '@/components/Pagination'
import templateTitle from '@/components/templateView/templateTitle.vue'
import formHandle from '@/views/postImmigrationService/policyIE/ImplementSituation/typicalSpotCheck/components/formHandle.vue'
import templateTree from '@/components/templateView/templateTree.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import tempalteImport from '@/components/templateView/tempalteImport.vue'

import { _getLevelRegion } from '@/api/keepHome'

export default {
  data() {
    return {
      type: '',
      showActions: false,
      selectedRows: [], // 当前选中的行
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      loading: false, //是显示加载
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择预算年度', trigger: 'blur' }]
      },
      listData: [],
      treeData: [],
      breadList: [
        {
          id: Math.random(),
          value: '列表页'
        }
      ],
      componentName: '',
      downloadObj: {},
      dck: [],
      OrgId: null,
      IsLevel: false,
      OrgPids: null,
      years: []
    }
  },
  // 注册组件
  components: {
    Pagination,
    templateTitle,
    formHandle,
    tempalteImport,
    templateTree,
    budgetYear
  },
  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.init()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    init() {
      this.getLevelRegion()
      //this.getPage()
    },
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },

    refreshTable() {
      this.getPage()
    },
       refreshNode(obj, IsLevel) {
      this.OrgId = obj.id
      this.downloadObj = obj
      this.IsLevel = IsLevel
      this.OrgPids = obj.pids
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
      this.getPage()
    },
    exportExcel() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log(this.downloadObj, '====this.downloadObj===')
          let params = {
            year: this.searchObj.year,
            OrgId: this.OrgId, //行政区域编号
            OrgName: this.downloadObj.name, //行政区域名称
            OrgPids: this.downloadObj.pids //行政区域编号path
          }

          console.log(params, '=============params================')
          // return
          _PorjectTypicalSpotChecksExport(params).then(res => {
            if (res.status == 200) {
              this.downloadfile(res)
            } else {
              this.$message.error('导出失败')
            }
          })
        } else {
          this.$message.error('请选择导出年度')
        }
      })
    },
    importExcel() {
      this.$refs.tempalteImport.view(this.searchObj.year, this.years, '1-4-4')
    },

    handleBack() {
      this.breadList.splice(1, 1)
      this.componentName = ''
    },
    // 获取page列表
    getPage() {
      const { currentPage, pageSize, total } = this.pageparm
      this.loading = true
      const params = {
        pageNo: currentPage,
        pageSize: pageSize,
        ...this.searchObj,
        OrgId: this.OrgId,
        OrgPids: this.OrgPids,
        IsLevel: this.IsLevel
      }

      _PorjectTypicalSpotChecksPage(params)
        .then(res => {
          if (res.code == 200) {
            this.listData = res.data.rows

            this.listData = res.data.rows
            this.pageparm.total = res.data.totalRows

            if (res.data.totalRow) {
              this.listData.unshift(res.data.totalRow)
            }

           

            this.loading = false
          } else {
            this.loading = false
            this.$message.error('获取失败：' + res.message)
          }
        })
        .finally(res => {
          this.loading = false
        })
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = [{ id: row.id }]
          _PorjectTypicalSpotChecksDelete(params).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            this.getPage()
            } else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 搜索事件
    search() {
      this.pageparm.currentPage = 1
      this.getPage()
    },
    refreshYear(year, years) {
      this.searchObj.year = year
      this.years = years
      this.pageparm.currentPage = 1
      this.getPage()
    },
    reset() {
      let nowYear = new Date().getFullYear()
      if (nowYear <= 2023) {
        this.searchObj = {
          year: nowYear - 1
        }
      } else {
        this.searchObj = {
          year: nowYear - 2
        }
      }

      this.OrgId = null
      this.IsLevel = false
      this.OrgPids = null
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }

      this.$refs.budgetYear.reset(this.searchObj.year)
      this.$refs.templateTree.resetLevel()

      this.getPage()
    },
    handleAdd(type, row) {
      let obj = {
        id: Math.random(),
        value: '新增'
      }
      this.breadList.splice(this.breadList.length, 0, obj)
      this.componentName = type
      this.type = type
      this.$nextTick(() => {
        this.$refs.formHandle.add(type)
      })
    },

    handleView(type, row) {
      let obj = {
        id: Math.random(),
        value: type == 'view' ? '详情' : '查看统计图'
      }
      this.breadList.splice(this.breadList.length, 0, obj)

      this.componentName = type
      this.type = type
      this.$nextTick(() => {
        this.$refs.formHandle.view(type, row)
      })
    },

    refreshTable() {
      this.getPage()
    },

    // 选择select

    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    },
    downloadfile(res) {
      var contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const regex = /filename*.+?\.xlsx/g
        const matches = contentDisposition.match(regex)
        const filename1 = matches[1].replace(/['"]/g, '')
        const startIndex = filename1.indexOf('filename*=UTF-8') + 'filename*=UTF-8'.length
        const result = filename1.substring(startIndex)
        const filename = result.replace(/^_+/g, '')
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(res.data) // 创建下载的链接
        var reg = /^["](.*)["]$/g
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } else {
        this.$message.error('导出失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';
::v-deep .el-form-item {
  margin-bottom: 0px;
}
</style>
